import styled from "styled-components";
import { device } from "../../Constants/Query";

export const ContainerPhoto = styled.div`
    margin-top: 0.75rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    box-shadow: 2px 2px 2px 2px #d3d3d3;
    padding: 2.5rem;
    gap: 0.5rem;
    background: #f9f9f9;
    align-items: center;
    justify-content: center;

    @media ${device.mobileS}{
        margin-top: 2rem;
        padding: 1rem;
        width: 70%;
    }

    @media ${device.laptop}{
        margin-top: 2rem;
        width: 60%;
        padding: 2.5rem;
    }
`
export const LabelArquivo = styled.label `
    width: 12rem;
    length: 10rem; 
    border-radius: 10px;
    border: #ccc solid;
    /* background-color: #d3d3d3; */
    padding: 10px 5px;
    text-align: center;
    margin: 1rem;
`

export const SomeInput = styled.input `
    display: none;
 
  `
  
export const Input = styled.input`
        display:none;
`

export const DivLabel = styled.div`
    display: flex;
    justify-content: space-around;
    margin: 2rem;
`
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../Constants/url";
import { Button } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { Header } from "../Constants/Header";
import { CardApsgeral, CardCentraliza, CardObras, ContainerGeral, ContainerPorcentagem, Linha, GridAptos } from "../Styled/StyledAdm/StyledInfoAdm";
import { useEffect, useState } from "react";
import axios from "axios";
import { useProtectedPage } from "../Hooks/useProtectedPage";
import { goToApFoto, goToLoginPage } from "../Routes/RouteFunctions";


export const InfoClientPage = () => {

    useProtectedPage();
    const navigate = useNavigate();
    const { type, id } = useParams();
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [data5, setData5] = useState(undefined)
    const [data2] = useState(false)
    const [data3] = useState([])
    const [value, setValue] = useState('')
    const [imgUrl, setImgUrl] = useState([]);
    const [apId, setApId] = useState('');
    let apLimpGrossa = [];
    let apLimpFina = [];
    let apConcluded = [];
    let apConcluded2 = [];
    let apLimpFina2 = [];
    let apLimpGrossa2 = [];

    const logout = (type) => {
        localStorage.removeItem("token")
        localStorage.removeItem("id")
        localStorage.removeItem("role")
        goToLoginPage(navigate, type)
    }

    const arrayLimpeza = (limpeza_completa) => {
        console.log('limpeza', limpeza_completa)
        if (limpeza_completa === 1) {
            apLimpGrossa2 = [...apLimpGrossa, limpeza_completa]
            apLimpGrossa = apLimpGrossa2
            return "Limpeza Grossa"
        } else if (limpeza_completa === 2) {
            apLimpFina2 = [...apLimpFina, limpeza_completa]
            apLimpFina = apLimpFina2
            return "Limpeza Fina"
        } else if (limpeza_completa === 3) {
            apConcluded2 = [...apConcluded, limpeza_completa]
            apConcluded = apConcluded2
            return "Pronto para Entrega"
        }
    };

    const GetInfo = () => {

        const token = localStorage.getItem("token");

        setLoadingEdit(true)

        axios.get(`${BASE_URL}/construction/client/${id}`, {
            headers: {
                authorization: token
            }
        }).then((response) => {
            setLoadingEdit(false)
            setData5(response.data.apartments)
            // console.log('data', response.data.apartments)

        }).catch((error) => {
            setLoadingEdit(false)
            console.log(error.response);

        });

    }

    useEffect(() => {
        GetInfo()
    }, [data2])


    const inf = !!data5 ? data5 : 'carregando'
    const info = inf
    const arrayAptos = info.apartamentos

    

    let total = !!arrayAptos ? arrayAptos.length : "não aparece o array"

    let totalApto = info.qty_andares * info.qty_ap_andar
    let totalLimpSemanal = (total / 7)
    let totalLimpMensal = (total / 30)

    console.log('total', total)

    

    const listaObra = () => {
             return <CardObras>
            <h1>{info.nome_obra} </h1> 
            <br></br>
            <h4>Total de andares: {info.qty_andares}</h4>
            <h4>Apartamentos por andar: {info.qty_ap_andar}</h4>
            <h4>Total de apartamentos: {totalApto}</h4>
            <h4>Tipo de contrato: {info.responsavel}</h4>
        </CardObras>
    };

    const url1 = !!data3 ? data3 : []
    const url2 = url1
    
    let andarFiltrado = []

    const separaAndar = (info) => {
       for (let i = 0; i < info.length; i++){
        info.filter(info => info.andar[i])
        return andarFiltrado.push(info[i])
       }
    }


    console.log('andarFiltrado', andarFiltrado)
    const ListInfos = arrayAptos && arrayAptos.map((info, i) => {
        return <CardCentraliza key={i}>
            {separaAndar(info)}
            <GridAptos>
                <h4>Apartamento: {info.numero_ap}</h4>
                <p>Limpeza: {arrayLimpeza(info.limpeza_completa)}</p>
                <p>Data:  {info.data}</p>
                <Button size="small" variant="contained" sx={{ height: 20, background: '#0000FF' }}
                    onClick={() => goToApFoto(navigate, info.obra_id, info.id)}>
                    Ver Fotos</Button>
            </GridAptos>
            
        </CardCentraliza>
    });


    return (
        <ContainerGeral>
            <Header />
            <Button sx={{ color: '#FFFFFF', background: '#0000FF' }} onClick={() => logout(type)}>sair</Button>
            {listaObra()}
            <CardCentraliza>
                {total === 0 ? <p>*Caso os totais estejam 0%, ainda não há apartamentos feitos</p> : ListInfos.sort()}
                <Linha></Linha>
                <ContainerPorcentagem>
                    <p><strong>Limpeza fina:</strong> {apLimpFina ?
                        ((apLimpFina.length / totalApto) * 100).toFixed(1) : 0}%</p>
                    <p><strong>Limpeza Grossa:</strong> {apLimpGrossa ?
                        ((apLimpGrossa.length / totalApto) * 100).toFixed(1) : 0}%</p>
                    <p><strong>Entrega:</strong> {apConcluded ?
                        ((apConcluded.length / totalApto) * 100).toFixed(1) : 0}%</p>
                </ContainerPorcentagem>
                <Linha></Linha>
                <ContainerPorcentagem>
                    {console.log("totao", total / 7)}
                    <p><strong>Porcentagem entrega semanal:</strong> {((totalLimpSemanal) * 100).toFixed(1)}%</p>
                    <p><strong>Porcentagem entrega mensal:</strong> {((totalLimpMensal) * 100).toFixed(1)}%</p>
                </ContainerPorcentagem>

                <Linha></Linha>
            </CardCentraliza>
            {loadingEdit && loadingEdit &&
                <CircularProgress sx={{ color: '#4498C6ff' }} spacing={4} /> && <div>Carregando...</div>}
        </ContainerGeral>
    )
};
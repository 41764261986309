import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdminPage from "../Pages/fluxo admin/AdminPage";
import CollabPage from "../Pages/fluxo collab/CollabsPage";
import { ConcludedAp } from "../Pages/fluxo collab/ConcludedAp";
import HomePage from "../Pages/Home/HomePage"
import LoginPage from "../Pages/Login/LoginPage";
import { NewProject } from "../Pages/fluxo admin/NewProject";
import { InfoPage } from "../Pages/infoPage";
import { SignUpPage } from "../Pages/Login/SignUpPage";
import { FotosConcludedAp } from "../Pages/fluxo Client/FotosConcludedAp";
import  InfoAdmPage  from "../Pages/InfoAdmPage";
import { LoginClientForm } from "../Pages/Login/LoginClientForm";
import { EditApartment } from "../Pages/fluxo collab/EditApartament";
import { InfoClientPage } from "../Pages/InfoClientPage";
import AddFoto from "../Pages/fluxo collab/AddFoto";


export default function Router() {
    return (

        <BrowserRouter>
        <Routes>
            <Route exact path={"/"} element={<HomePage />} />
            <Route path={"/login/:type"} element={<LoginPage />} />
            <Route path={"/user/:type"} element={<AdminPage />} />
            <Route path={"/userC/:type/:id"} element={<CollabPage />} />
            <Route path={"/userB/:type"} element={<LoginClientForm />} />
            <Route path={"/userA/:type/:id"} element={<InfoClientPage />} />
            <Route path={"/apartment/:obra_id"} element={<ConcludedAp />} />
            <Route path={"/ap_foto/:obra_id/:ap_id/"} element={<FotosConcludedAp />} />
            <Route path={"/addfoto/:obra_id/:ape_id"} element={<AddFoto />} />
            <Route path={"/new_build"} element={<NewProject />} />
            <Route path={"/apADM/:type/:obra_id"} element={<InfoAdmPage />} />
            <Route path={"/info/:type/:obra_id"} element={<InfoPage />} />
            <Route path={"/info/:type/:id"} element={<InfoClientPage />} />
            <Route path={"/info_ap/:type/:id/:obra_id"} element={<InfoPage />} />
            <Route path={"/edit/:ap_id"} element={<EditApartment />} />
            <Route path={"/sign_up/:type"} element={<SignUpPage />} />
        </Routes>
    </BrowserRouter>

    )
};

// 
import { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../../Constants/Header";
import { Button } from "@mui/material";
import { useProtectedPage } from "../../Hooks/useProtectedPage";
import SendIcon from '@mui/icons-material/Send';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CircularProgress from '@mui/material/CircularProgress';
import { storage } from "../../Config/firebase";
import { getDownloadURL, list, listAll, ref  } from "firebase/storage";
import { ContainerGeral, CardGeralFotos, FotosAps, Images } from "../../Styled/StyledClient/StyledInfoConcludedFoto";


export const FotosConcludedAp = () => {

    useProtectedPage();
    const user_id = localStorage.getItem('id')
    const {obra_id, ap_id} = useParams();
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [imgUrl, setImgUrl] = useState([]);
    // const [images, setImages] = useState([]);
    // const [progresso, setProgresso] = useState(0);
    
    let imgListRef = ref(storage, `images/${obra_id}/${ap_id}`)
    
    useEffect (() => {
        listAll(imgListRef)
            .then((response)=>{
               response.items.forEach((item)=> {
                getDownloadURL(item)
                    .then((url) => {
                        setImgUrl((prev) => [...prev, url]);
                        
                });
            });
        }); 
    }, []);

    console.log('img', imgUrl)
    const arrayLimpo = imgUrl && imgUrl.filter((este, i) => imgUrl.indexOf(este) === i) // #######tira as imagens duplicadas
    
    return (
        <ContainerGeral>
            <Header />
            <Button variant="contained" sx={{background: '#0000FF'}} startIcon={<ArrowBackIosIcon />} 
            onClick={() => navigate(-1)}>Voltar</Button>
            <CardGeralFotos>
               {arrayLimpo.map((url,i) =>{
                return <FotosAps key={i}> 
                            <Images src={url} alt={'foto apartamento'}></Images>
                    </FotosAps>})
            }
            {!arrayLimpo ? <h4>Ops, ainda não foram carregadas fotos!</h4> : ''}
            </CardGeralFotos>
       </ContainerGeral>
    )
};
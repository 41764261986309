import { useState } from "react";
import { Button, Input } from "@mui/material";
import { Header } from "../../Constants/Header";
import { goToCollabPage } from "../../Routes/RouteFunctions";
import { useNavigate, useParams } from "react-router-dom";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../Config/firebase";
import Swal from "sweetalert2";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SendIcon from '@mui/icons-material/Send';
import { ContainerGeral, DivLabel, LabelArquivo, FormFoto, SomeInput, Image } from "../../Styled/StyledCollab/StyledConcludedAp";
import { ContainerPhoto } from "../../Styled/StyledClient/StyledFoto";

export default function AddFoto() {

    const { obra_id, ape_id } = useParams();
    const [imageUrl, setImageUrl] = useState('');
    const [progress, setProgress] = useState(0)
    const navigate = useNavigate();
    const user_id = localStorage.getItem('id')
    const type = localStorage.getItem('role')


    console.log('apid', ape_id)

    const onSelectedFiles = (e) => {
        e.preventDefault();

        const file = e.target[0]?.files[0]
        if (!file) return;

        const imageRef = ref(storage, `images/${obra_id}/${ape_id}/${file.name}`); //caminho onde serão salvas as imgs no firebase
        const uploadTask = uploadBytesResumable(imageRef, file)

        uploadTask.on(
            "state_changed",
            snapshot => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setProgress(progress)
            },
            error => {
                alert(error)
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(url => {
                    setImageUrl(url)
                })
            }
        )

        // uploadBytes(imageRef, images).then(() => {  //requisição que envia a foto para o storage do firebase no caminho de cima
        //     // Swal.fire({
        //     //     icon: "succes",
        //     //     title: "Sucesso!",
        //     //     text: "Imagem Salva!"
        //     // });
        //     alert("imagem salva, adicione nova ou volte ao menu principal")
        // }).catch((error)=>{
        //     console.error(error)
        //     // Swal.fire({
        //     //     icon: "error",
        //     //     title: "Oops...",
        //     //     text: "deu erro! Tente novamente"
        //     //   });
        //     alert("erro")
        // })
    };

    return (
        <ContainerGeral>
            <Header />
            <Button variant="contained" startIcon={<ArrowBackIosIcon />} onClick={() => goToCollabPage(navigate, type, user_id)}>finalizar</Button>
            <ContainerPhoto>
                <p>Adicione a foto desejada e clique em "add foto". Caso tenha mais de uma, repita o processo</p>
                <FormFoto onSubmit={onSelectedFiles}>
                    <DivLabel>
                        <LabelArquivo for="firebase-file">selecionar foto
                            <SomeInput id="firebase-file" type={"file"} />
                        </LabelArquivo>
                        {!imageUrl && <progress value={progress} max="100" />}
                        {imageUrl && <Image src={imageUrl} alt='imagem' />}
                    </DivLabel>
                    <Button variant="contained" endIcon={<SendIcon />} type={"submit"} >add Foto</Button>
                </FormFoto>

            </ContainerPhoto>
        </ContainerGeral>
    )
}
import { Button, IconButton } from "@mui/material";
import axios from "axios";
import { useState, useRef, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../Constants/url";
import SendIcon from '@mui/icons-material/Send';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useProtectedPage } from "../../Hooks/useProtectedPage";
import {
    ContainerCard, ContainerChecklist, ContainerGeral, FormFoto, LabelArquivo, Input, DivLabel, InputFoto, ContainerUpload,
    InputChecklist, Image, ImageGrid
} from "../../Styled/StyledCollab/StyledConcludedAp";
import { Header } from "../../Constants/Header";
import Swal from "sweetalert2";
import { v4 } from "uuid";
import { goToAddFoto, goToCollabPage } from "../../Routes/RouteFunctions";

export const ConcludedAp = () => {
    useProtectedPage();
    const { type, obra_id } = useParams();
    const navigate = useNavigate();
    const [level, setLevel] = useState('');
    const [conclusion, setConclusion] = useState('');
    const [loading, setLoading] = useState(false);
    const [ape, setApe] = useState('');
    const user_id = localStorage.getItem('id')
    const [caxilho, setCaxilho] = useState(false);
    const [paredes, setParedes] = useState(false);
    const [pisos, setPisos] = useState(false);
    const [tomadas, setTomadas] = useState(false);
    const [banheiros, setBanheiros] = useState(false);
    const [sacadas, setSacadas] = useState(false);
    const [bancadas, setBancadas] = useState(false);
    const [tetos, setTetos] = useState(false);
    const [portas, setPortas] = useState(false);
    const [metragem, setMetragem] = useState(0);
    
    const ape_id = v4()
       
    const uploadImage = async (e) => {
        setLoading(true)
        const token = localStorage.getItem("token");
       
        let body = {
            ape_id: ape_id,
            numero_ap: ape, 
            andar: level, 
            limpeza_completa: conclusion, 
            obra_id: obra_id, 
            caxilho: caxilho, 
            paredes: paredes, 
            pisos: pisos, 
            tomadas: tomadas, 
            tetos: tetos, 
            banheiros: banheiros,
            portas: portas, 
            bancadas: bancadas, 
            sacadas: sacadas, 
            metragem: metragem
        }

        e.preventDefault();
        axios.post(`${BASE_URL}/apartments/newAp/${user_id}`, body, {

            headers: {
                authorization: token
            }
        }).then((response) => {
            goToAddFoto(navigate, obra_id, ape_id)
              

        }).catch(error => {
            goToCollabPage(navigate, type, user_id)
            console.log(error)
        });
    };

    const lista = () => {
        return <ContainerCard encType="multipart/form-data" onSubmit={uploadImage}>
            <TextField fullWidth required
                id="outlined-required1"
                label="Número do Andar"
                onChange={(e) => setLevel(e.target.value)} />
            <TextField fullWidth required
                id="outlined-required2"
                label="Nº Ap/local"
                onChange={(e) => setApe(e.target.value)} />
            <TextField fullWidth required
                id="outlined-required3"
                label="metragem"
                onChange={(e) => setMetragem(e.target.value)} />
            <ContainerChecklist>
                <label>
                    <InputChecklist type='checkbox' onClick={() => setCaxilho(true)} />Caxilho
                    <InputChecklist type='checkbox' onClick={() => setParedes(true)} />Paredes
                    <InputChecklist type='checkbox' onClick={() => setPisos(true)} />Pisos
                    <InputChecklist type='checkbox' onClick={() => setTomadas(true)} />Tomadas
                    <InputChecklist type='checkbox' onClick={() => setBanheiros(true)} />Banheiros
                    <InputChecklist type='checkbox' onClick={() => setTetos(true)} />Tetos
                    <InputChecklist type='checkbox' onClick={() => setSacadas(true)} />Sacadas
                    <InputChecklist type='checkbox' onClick={() => setPortas(true)} />Portas
                    <InputChecklist type='checkbox' onClick={() => setBancadas(true)} />Bancadas
                </label>
            </ContainerChecklist>

            <FormControl >
                <InputLabel id="demo-simple-select-autowidth-label">Limpeza:</InputLabel>
                <Select sx={{ minWidth: 80 }}
                    required
                    labelId="demo-simple-select-label"
                    label="Limpeza"
                    id="demo-simple-select"
                    value={conclusion}
                    onChange={(e) => setConclusion(e.target.value)}
                >
                    <MenuItem value={1}>Limpeza Grossa</MenuItem>
                    <MenuItem value={2}>Limpeza Fina</MenuItem>
                    <MenuItem value={3}>Entrega</MenuItem>
                </Select>
            </FormControl>
            <Button variant="contained" endIcon={<SendIcon />} type={"submit"}>Enviar</Button>
    </ContainerCard >
    };

    console.log('ape', ape)
    console.log('level', level)
    console.log('metragem', metragem)
    console.log('piso', pisos)

    return (
        <ContainerGeral>
            <Header />
            <Button variant="contained" startIcon={<ArrowBackIosIcon />} onClick={() => navigate(-1)}>Voltar</Button>
            {lista()}
            {loading && loading &&
                <CircularProgress sx={{ color: '#4498C6ff' }} spacing={4} />}
        </ContainerGeral>


    )

};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// const filesElement = useRef(null);
// const [image, setImage] = useState('');

// Input file antigo

/* <input type={"file"} multiple ref={filesElement} name="avatar" onChange={onSelectedFiles} />

{image ? <ImageGrid> {image && image.map((imagem) => {
    return<div>
        <Image src={imagem}></Image>
    </div>
})} </ImageGrid> : "adicione uma foto"} */

//onchange e endpoint para enviar a foto

// const onSelectedFiles = (event) => {
//     const selectedFiles = event.target.files;
//     const selectedFilesArray = Array.from(selectedFiles);

//     const imageArray = selectedFilesArray.map((file) => {
//         return URL.createObjectURL(file)
//     })
//     setImage(imageArray)
// };

// console.log('image', image)

// const uploadImage = async (e) => {
//     setLoading(true)
//     e.preventDefault()
//     const token = localStorage.getItem("token");
//     const dataForm = new FormData();

//     let file = []

//     for (const file2 of filesElement.current.files) {

//         dataForm.append('avatar', file2);
//         dataForm.append('numero_ap', ape);
//         dataForm.append('andar', level);
//         dataForm.append('limpeza_completa', conclusion);
//         dataForm.append('obra_id', obra_id);
//         dataForm.append('caxilho', caxilho);
//         dataForm.append('paredes', paredes);
//         dataForm.append('pisos', pisos);
//         dataForm.append('tomadas', tomadas);
//         dataForm.append('tetos', tetos);
//         dataForm.append('banheiros', banheiros);
//         dataForm.append('portas', portas);
//         dataForm.append('bancadas', bancadas);
//         dataForm.append('sacadas', sacadas);
//         file = dataForm
//     }
//     axios.post(`${BASE_URL}/apartments/newAP/${user_id}`, dataForm, {
//         headers: {
//             authorization: token,
//             ContentType: "multipart/form-data"
//         }
//     }).then((response) => {
//         setLoading(false);
//         alert("Informações Salvas, Apartamento Concluído!")
//         console.log(response.data)
//         navigate(-1)


//     }).catch(error => {
//         setLoading(false)
//         console.log(error)
//         // setErro(error.response)
//         // console.log("deu erro!", error.response)
//     });


// };

// const onSelectedFiles = () => {
        
//     if (images === null) return;

//     const imageRef = ref(storage, `images/${images.name + obra_id()}`);

//     uploadBytes(imageRef, images).then(() => {
//         alert("image uploaded!")
//     }).catch((error)=>{
//         console.error(error)
//     })
// };

